
import { FormRules } from '@/mixins/FormRules';
import { Feedback } from '@/models';
import { ErrorManager } from '@/models/error';
import Vue from 'vue';
import { mapActions } from 'vuex';
export default Vue.extend({
  mixins: [FormRules],

  name: 'FeedbackSupportDialog',

  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,

      errorMessage: '',
      name: '' as string,
      email: '' as string,
      comment: '' as string
    };
  },

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      sendFeedback: 'feedback/sendFeedback'
    }),

    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;

      this.clearAllErrors();

      const feedbackForm = this.$refs.form as any;
      feedbackForm.reset();
    },

    clearAllErrors(): void {
      this.errorMessage = '';
    },

    sendMessage() {
      this.clearAllErrors();

      this.loading = true;

      const feedbackForm = new Feedback({
        name: this.name,
        email: this.email,
        comment: this.comment
      });

      this.sendFeedback(feedbackForm)
        .then(() => {
          this.close();
          this.$emit('setConfirmationSnackbar');
        })
        .catch((error) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
